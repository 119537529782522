import { styled, makeStyles } from '@material-ui/styles';
import {
  Container,
  Table,
  Typography,
  TextField,
  Box,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';

export const Window = styled(Container)({
  width: '100%',
  paddingTop: '32px',
});

export const MyTable = styled(Table)({
  width: '100%',
});

export const Title = styled(Typography)({
  flexGrow: 1,
});

export const Form = styled(Box)({
  padding: '16px',
  paddingTop: '16px',
  marginBottom: '32px',
  boxSizing: 'border-box',
});

export const FormInput = styled(TextField)({
  width: props => (props.width ? props.width : '100%'),
  marginLeft: props => (props.margin ? props.margin : '16px'),
});

export const Row = styled(Box)({
  display: 'flex',
  marginBottom: '16px',
});

export const DatePicker = styled(DateTimePicker)({
  marginLeft: '16px',
});

export const useStyles = makeStyles({
  done: {
    background: 'rgba(76, 175, 80, .25)',
  },
  buttons: {
    minWidth: '180px',
  },
});

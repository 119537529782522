import React, { useState } from 'react';
import { LoginInput, Window, Main, LoginButton } from './styles';
import firebase from '../../utils/firebase';
import 'firebase/auth';

const Login = props => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(false);

  const handleLogin = e => {
    e.preventDefault();
    setDisabled(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => (window.location.href = '/'))
      .catch(err => {
        setDisabled(false);
      });
  };

  return (
    <Window>
      <Main boxShadow={2}>
        <form onSubmit={handleLogin}>
          <LoginInput
            fullWidth
            label="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="email"
            disabled={disabled}
          />
          <LoginInput
            label="Senha"
            fullWidth
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
            disabled={disabled}
          />
          <LoginButton
            type="submit"
            disabled={disabled}
            variant="contained"
            color="primary"
          >
            Entrar
          </LoginButton>
        </form>
      </Main>
    </Window>
  );
};

export default Login;

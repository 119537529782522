import { styled } from '@material-ui/styles';
import { Container, Box, Button, TextField } from '@material-ui/core';

export const LoginInput = styled(TextField)({
  display: 'block',
  marginBottom: '24px',
});

export const Window = styled(Container)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Main = styled(Box)({
  minWidth: '400px',
  padding: '16px',
});

export const LoginButton = styled(Button)({
  width: '100%',
});

import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import firebase from '../../utils/firebase';
import 'firebase/database';

const RemoveDialog = props => {
  var database = firebase.database();

  const deleteOrder = () => {
    database
      .ref(`orders/${props.id}`)
      .remove()
      .then(props.setToggleRemove(false));
  };

  return (
    <Dialog
      onClose={() => props.setToggleRemove(false)}
      open={props.toggleRemove}
    >
      <DialogTitle>Deseja realmente remover essa ordem?</DialogTitle>
      <DialogActions>
        <Button onClick={() => props.setToggleRemove(false)}>Cancelar</Button>
        <Button onClick={() => deleteOrder()}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDialog;

import React, { useState, useEffect } from 'react';
import { DialogTitle, DialogContent, Button, Dialog } from '@material-ui/core';
import { Row, FormInput, DatePicker } from '../../pages/Home/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import firebase from '../../utils/firebase';
import 'firebase/database'; 

var database = firebase.database();

const Modal = props => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [number, setNumber] = useState('');
  const [city, setCity] = useState('');
  const [typeService, setTypeService] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (cep !== undefined) {
      if (cep.length === 8) {
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
          .then(data => {
            return data.json();
          })
          .then(data => {
            setAddress(data.logradouro);
            setNeighborhood(data.bairro);
            setCity(data.localidade);
          });
      }
    }
  }, [cep]);

  useEffect(() => {
    setName(props.info.name);
    setPhone(props.info.phone);
    setCep(props.info.zipcode);
    setAddress(props.info.address);
    setNeighborhood(props.info.neighborhood);
    setNumber(props.info.number);
    setCity(props.info.city);
    setTypeService(props.info.type);
    setDate(props.info.timestamp * 1000);
    setDescription(props.info.description);
  }, [props.toggleModal, props.info]);

  const handleSubmit = e => {
    e.preventDefault();
    const data = {
      name,
      phone,
      zipcode: cep,
      address,
      neighborhood,
      number,
      city,
      type: typeService,
      timestamp: +new Date(date) / 1000,
      description,
    };

    database
      .ref(`orders/${props.info.id}`)
      .set(data)
      .then(props.setToggleModal(false));
  };

  return (
    <Dialog
      onClose={() => props.setToggleModal(false)}
      maxWidth="xl"
      open={props.toggleModal}
    >
      <DialogTitle>Editando Ordem</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Row>
            <FormInput
              required
              label="Nome"
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="Nome"
              margin="none"
            />

            <FormInput
              required
              label="Telefone"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              placeholder="Telefone"
              width="30%"
            />
          </Row>
          <Row>
            <FormInput
              required
              label="CEP"
              value={cep}
              onChange={e => setCep(e.target.value)}
              placeholder="CEP"
              width="15%"
              margin="none"
            />
            <FormInput
              required
              label="Endereço"
              value={address}
              onChange={e => setAddress(e.target.value)}
              placeholder="Endereço"
              width="40%"
            />
            <FormInput
              required
              label="Bairro"
              value={neighborhood}
              onChange={e => setNeighborhood(e.target.value)}
              placeholder="Bairro"
              width="40%"
            />
            <FormInput
              required
              label="Número"
              value={number}
              onChange={e => setNumber(e.target.value)}
              placeholder="Número"
              width="15"
            />
            <FormInput
              required
              label="Cidade"
              value={city}
              onChange={e => setCity(e.target.value)}
              placeholder="Cidade"
              width="30%"
            />
          </Row>
          <Row>
            <FormInput
              required
              label="Tipo de Serviço"
              value={typeService}
              onChange={e => setTypeService(e.target.value)}
              placeholder="Tipo de Serviço"
              width="85%"
              margin="none"
            />
            <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
              <DatePicker
                label="Data e Hora"
                ampm={false}
                value={date}
                onChange={setDate}
                format="[Dia] DD/MM [às] HH:mm"
              />
            </MuiPickersUtilsProvider>
          </Row>
          <Row>
            <FormInput
              value={description}
              onChange={e => setDescription(e.target.value)}
              multiline
              label="Descrição"
              margin="none"
            />
          </Row>
          <Button type="submit" color="primary" variant="contained" fullWidth>
            Editar
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;

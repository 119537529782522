import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

const InfoDialog = props => {
  return (
    <Dialog
      onClose={() => props.setToggleInfo(false)}
      maxWidth="md"
      fullWidth
      open={props.toggleInfo}
      scroll="paper"
    >
      <DialogTitle>Descrição</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.info}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setToggleInfo(false)}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;

import React, { useState, useEffect, useRef } from 'react';
import {
  Window,
  MyTable,
  Title,
  Form,
  FormInput,
  Row,
  DatePicker,
  useStyles,
} from './styles';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Toolbar,
  AppBar,
  Box,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Delete, Create, ExitToApp, Info } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import firebase from '../../utils/firebase';
import 'firebase/database';
import 'firebase/messaging';
import 'firebase/auth';
import 'firebase/functions';
import Modal from '../../components/Modal';
import 'moment/locale/pt-br';
import moment from 'moment';
import InfoDialog from '../../components/InfoDialog';
import RemoveDialog from '../../components/RemoveDialog';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

moment.locale('pt-br');

let database = firebase.database();
let messaging = firebase.messaging();

const Home = () => {
  const [data, setData] = useState([]);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [number, setNumber] = useState('');
  const [city, setCity] = useState('');
  const [typeService, setTypeService] = useState('');
  const [date, setDate] = useState(new Date());
  const [description, setDescription] = useState('');

  const [toggleModal, setToggleModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);

  const [toggleInfo, setToggleInfo] = useState(false);
  const [infoDescription, setInfoDescription] = useState(false);

  const [toggleRemove, setToggleRemove] = useState(false);
  const [removeId, setRemoveId] = useState('');

  const numberRef = useRef();

  useEffect(() => {
    database.ref('orders').orderByChild('timestamp').startAt(0).on('value', snapshot => {
      const ordersUpdate = [];
      snapshot.forEach(childSnapshot => {
        ordersUpdate.push({
          id: childSnapshot.key,
          ...childSnapshot.val(),
        });
      });
      setData(ordersUpdate);
    });
  }, []);

  useEffect(() => {
    if (cep.length === 8 || cep.length === 9) {
      fetch(`https://viacep.com.br/ws/${cep.replace('-', '')}/json/`)
        .then(data => {
          return data.json();
        })
        .then(data => {
          setAddress(data.logradouro);
          setNeighborhood(data.bairro);
          setCity(data.localidade);
          numberRef.current.focus();
        });
    }
  }, [cep]);

  const handleSubmit = e => {
    e.preventDefault();
    const data = {
      name,
      phone,
      zipcode: cep,
      address,
      neighborhood,
      number,
      city,
      type: typeService,
      timestamp: +new Date(date) / 1000,
      description,
    };

    database.ref('orders').push(data, error => {
      setName('');
      setPhone('');
      setCep('');
      setAddress('');
      setNeighborhood('');
      setNumber('');
      setCity('');
      setTypeService('');
      setDate(new Date());
      setDescription('');
    });
  };

  const openInfo = description => {
    setInfoDescription(description);
    setToggleInfo(true);
  };

  const deleteOrder = id => {
    setToggleRemove(true);
    setRemoveId(id);
  };

  const editOrder = item => {
    setToggleModal(true);
    setModalInfo(item);
  };

  const styles = useStyles();

  useEffect(() => {
    navigator.serviceWorker
      .register('./firebase-messaging-sw.js', {
        scope: 'firebase-cloud-messaging-push-scope',
      })
      .then(registration => {
        messaging.useServiceWorker(registration);
      });

    Notification.requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then(token => {
        const addToTopic = firebase.functions().httpsCallable('addToTopic');
        addToTopic({ token });
      })
      .catch(e => {
        toast.error("Para melhor experiência, recomendamos ativar as notificações",
        {
          autoClose: 10000
        });
      });

    messaging.onMessage(payload => {
      toast.info(payload.notification.body);
    });
  }, []);

  return (
    <>
          <AppBar position="sticky">
        <Toolbar>
          <Title type="title" variant="h6">
            Ordens de Serviço
          </Title>
          <IconButton onClick={() => firebase.auth().signOut()}>
            <ExitToApp htmlColor="#ffffff" />
          </IconButton>
        </Toolbar>
      </AppBar>
    <Window>

      <Form boxShadow={2}>
        <Box mb={1}>
          <Typography>Adicionar Nova Ordem</Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Row>
            <FormInput
              required
              label="Nome"
              value={name}
              onChange={e => setName(e.target.value)}
              margin="none"
            />

            <FormInput
              required
              label="Telefone"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              width="30%"
            />
          </Row>
          <Row>
            <FormInput
              required
              label="CEP"
              value={cep}
              onChange={e => setCep(e.target.value)}
              width="15%"
              margin="none"
            />
            <FormInput
              required
              label="Endereço"
              value={address}
              onChange={e => setAddress(e.target.value)}
              width="40%"
            />
            <FormInput
              required
              label="Bairro"
              value={neighborhood}
              onChange={e => setNeighborhood(e.target.value)}
              width="40%"
            />
            <FormInput
              required
              label="Número"
              inputRef={numberRef}
              value={number}
              onChange={e => setNumber(e.target.value)}
              width="15"
            />
            <FormInput
              required
              label="Cidade"
              value={city}
              onChange={e => setCity(e.target.value)}
              width="30%"
            />
          </Row>
          <Row>
            <FormInput
              required
              label="Tipo de Serviço"
              value={typeService}
              onChange={e => setTypeService(e.target.value)}
              width="85%"
              margin="none"
            />
            <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
              <DatePicker
                label="Data e Hora"
                ampm={false}
                value={date}
                onChange={setDate}
                format="[Dia] DD/MM [às] HH:mm"
              />
            </MuiPickersUtilsProvider>
          </Row>
          <Row>
            <FormInput
              value={description}
              onChange={e => setDescription(e.target.value)}
              multiline
              label="Descrição"
              margin="none"
            />
          </Row>
          <Button color="primary" variant="contained" fullWidth type="submit">
            Cadastrar
          </Button>
        </form>
      </Form>

      <AppBar position="sticky">
        <Toolbar>
          <Title type="title" variant="h6">
            Serviços
          </Title>
        </Toolbar>
      </AppBar>
      <Box boxShadow={1} width="100%">
        <MyTable>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell>Endereço</TableCell>
              <TableCell>Tipo de Serviço</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(item => (
              <TableRow
                className={item.done ? styles.done : null}
                key={item.id}
              >
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.phone}</TableCell>
                <TableCell>
                  {`${item.address}, ${item.number} - `}
                  {`${item.neighborhood}, ${item.city}`}
                </TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>{moment.unix(item.timestamp).calendar()}</TableCell>
                <TableCell className={styles.buttons}>
                  <IconButton onClick={() => openInfo(item.description)}>
                    <Info />
                  </IconButton>
                  <IconButton onClick={() => editOrder(item)}>
                    <Create />
                  </IconButton>
                  <IconButton onClick={() => deleteOrder(item.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MyTable>
      </Box>
      <Modal
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        info={modalInfo}
      />
      <InfoDialog
        toggleInfo={toggleInfo}
        setToggleInfo={setToggleInfo}
        info={infoDescription}
      />
      <RemoveDialog
        toggleRemove={toggleRemove}
        setToggleRemove={setToggleRemove}
        id={removeId}
      />
      <ToastContainer position="top-right" />
    </Window>
    </>
  );
};

export default Home;

import { styled } from '@material-ui/styles';
import { Container, Box } from '@material-ui/core';

export const Window = styled(Container)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Main = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

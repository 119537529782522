import React from 'react';
import { Window, Main } from './styles';
import { Box, Typography, CircularProgress } from '@material-ui/core';

const Loading = () => {
  return (
    <Window>
      <Main paddingY={4} paddingX={12} boxShadow={2}>
        <Typography variant="h5">Carregando...</Typography>
        <Box marginTop={4}>
          <CircularProgress />
        </Box>
      </Main>
    </Window>
  );
};

export default Loading;
